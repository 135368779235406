import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './Spinner.module.css'

const spinner = `/${process.env.REACT_APP_VARIATION_PATH}/icons/spinner.gif`

const Spinner = ({ className }) => {
    const { t } = useTranslation()

    return (
        <div className={cx(styles.wrapper, className)}>
            <img src={spinner} alt={t('general.loading')} />
        </div>
    )
}

Spinner.propTypes = {
    className: PropTypes.string,
}

export default Spinner
