import React, { useState } from 'react'
import PropTypes from 'prop-types'
import uniqueId from 'lodash/uniqueId'

import styles from './FilePicker.module.css'

const FilePicker = ({ children, className, onChange }) => {
    const [id] = useState(uniqueId('file-input-'))
    return (
        <div className={className}>
            <label htmlFor={id}>{children}</label>
            <input
                id={id}
                className={styles.input}
                onChange={event => {
                    onChange(event.target.files || event.dataTransfer.files)
                    event.target.value = ''
                }}
                type="file"
                accept="image/*"
                capture="camera"
            />
        </div>
    )
}

FilePicker.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

export default FilePicker
