import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import cx from 'classnames'

import Audio from 'components/common/Audio'
import ErrorModal from 'components/common/Modals/ErrorModal'
import LoadingModal from 'components/common/Modals/LoadingModal'
import WarningModal from 'components/common/Modals/WarningModal'
import NextButton from 'components/common/Buttons/NextButton'
import TextIconButton from 'components/common/Buttons/TextIconButton'
import UploadButton from 'components/common/Buttons/UploadButton'
import ImagePicker from 'containers/ImagePicker'
import SessionProgressDescription from 'containers/SessionProgressDescription'
import { isApp } from 'utils/userAgent'

import styles from './AddImages.module.css'

const camera = `/${process.env.REACT_APP_VARIATION_PATH}/icons/camera.png`
const next = `/${process.env.REACT_APP_VARIATION_PATH}/icons/arrow-right.png`

const audioOverviewStart = `/${process.env.REACT_APP_VARIATION_PATH}/audio/overview-start.mp3`
const audioOverviewFinish = `/${process.env.REACT_APP_VARIATION_PATH}/audio/overview-finish.mp3`

class AddImages extends React.Component {
    getImages() {
        return (
            <div className={styles.images}>
                {[...Array(this.props.project.images)].map((x, i) => (
                    <ImagePicker
                        key={i + 1}
                        imageIndex={i + 1}
                        sessionId={this.props.sessionId}
                        projectId={this.props.project.id}
                    />
                ))}
            </div>
        )
    }

    componentDidMount() {
        this.props = { ...this.props, project: null }
        this.props.fetchSessionData(this.props.sessionId)
    }

    componentWillUnmount() {
        this.props.hideOverlays()
    }

    handleFiles = files => {
        const [file] = files
        if (file) this.props.addImage(this.props.sessionId, file)
    }

    requestRendering = forceRendering => {
        this.props.requestRendering({
            sessionId: this.props.sessionId,
            project: this.props.project,
            croppedImageUrls: this.props.croppedImageUrls,
            force: forceRendering,
        })
    }

    render() {
        const { t } = this.props
        document.body.style.overflow =
            this.props.isLoading || this.props.uploadError || this.props.warningModal || this.props.imgAmountWarn
                ? 'hidden'
                : 'unset'

        if (!this.props.project) {
            this.props.fetchSessionData(this.props.sessionId)
            return <LoadingModal text={t('addImages.loading')}></LoadingModal>
        }

        if (this.props.jobId) {
            return <Redirect to={`/jobs/${this.props.jobId}`} />
        }

        return (
            <>
                {this.props.isLoading && <LoadingModal></LoadingModal>}
                {this.props.uploadError && (
                    <ErrorModal title={t('addImages.error')}>
                        <UploadButton onChange={this.handleFiles} text={t('addImages.again')}></UploadButton>
                    </ErrorModal>
                )}
                {this.props.warningModal && <WarningModal title={t('warning.back')}></WarningModal>}
                {this.props.imgAmountWarn && (
                    <ErrorModal className={styles.warning} title={t('addImages.warn')}>
                        <div className={styles.buttons}>
                            <TextIconButton
                                className={styles.button}
                                text={t('addImages.more')}
                                icon={camera}
                                onClick={() => this.props.hideOverlays()}
                            />
                            <TextIconButton
                                className={styles.button}
                                text={t('addImages.next')}
                                icon={next}
                                onClick={() => this.requestRendering(true)}
                            />
                        </div>
                    </ErrorModal>
                )}

                <div className={styles.wrapper}>
                    <Helmet>
                        <title>{t('addImages.title', { title: this.props.project.title })}</title>
                    </Helmet>

                    {Object.keys(this.props.uploadedImages).length === this.props.requiredImages.length && (
                        <>
                            <NextButton onClick={() => this.requestRendering(false)} />
                            {isApp() && <Audio source={audioOverviewFinish} />}
                        </>
                    )}
                    <SessionProgressDescription />
                    {this.getImages()}

                    <TextIconButton
                        className={cx(styles.button, styles.next)}
                        text={t('addImages.next')}
                        icon={next}
                        onClick={() => this.requestRendering(false)}
                    />
                </div>
                {isApp() && this.props.requiredImages.length > 1 && <Audio source={audioOverviewStart} />}
            </>
        )
    }
}

AddImages.propTypes = {
    fetchSessionData: PropTypes.func.isRequired,
    requestRendering: PropTypes.func.isRequired,
    project: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        images: PropTypes.number.isRequired,
    }),
    sessionId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
}

export default withTranslation()(AddImages)
