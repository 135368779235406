import React from 'react'
import PropTypes from 'prop-types'

const Audio = ({ source }) => {
    return <audio src={source} autoPlay />
}

Audio.propTypes = {
    source: PropTypes.string.isRequired,
}

export default Audio
