import React from 'react'
import PropTypes from 'prop-types'

import styles from './RenderVideo.module.css'

const RenderedVideo = ({ videoUrl, previewUrl }) => {
    return (
        <video poster={previewUrl} className={styles.video} controls>
            <source src={videoUrl} type="video/mp4" />
        </video>
    )
}

RenderedVideo.propTypes = {
    videoUrl: PropTypes.string,
    previewUrl: PropTypes.string,
}

export default RenderedVideo
