import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import Text from 'components/common/Text'

import styles from './SessionProgressDescription.module.css'

const SessionProgressDescription = ({ className, title, uploaded, total }) => {
    const { t } = useTranslation()
    return (
        <div className={cx(styles.wrapper, className)}>
            <Text>{t('session.title', { title })}</Text>
            <Text>{t('session.images', { uploaded, total })}</Text>
        </div>
    )
}

SessionProgressDescription.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    uploaded: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
}

export default SessionProgressDescription
