import { compose } from 'redux'
import { connect } from 'react-redux'

import Rendering from './Rendering'
import withHeader from 'hocs/withHeader'
import { fetchJobStatus } from 'redux/actions/job/fetchStatus'
import { fetchSessionData } from 'redux/actions/session/fetch'
import { showBasicModal } from 'redux/actions/ui/showBasicModal'
import { showQuestionModal } from 'redux/actions/ui/showQuestionModal'

const mapDispatchToProps = dispatch => ({
    fetchJobStatus: id => dispatch(fetchJobStatus(id)),
    fetchSessionData: id => dispatch(fetchSessionData(id)),
    showBasicModal: () => dispatch(showBasicModal()),
    showQuestionModal: () => dispatch(showQuestionModal()),
})

const mapStateToProps = (state, props) => ({
    ...props,
    ...state.ui.overlay,
    job: state.job,
    session: state.session,
    jobId: props.match.params.id,
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withHeader)

export default enhance(Rendering)
