import React from 'react'
import { Link } from 'react-router-dom'

import Audio from 'components/common/Audio'
import Delayed from 'components/common/Delayed'
import ErrorModal from 'components/common/Modals/ErrorModal'
import LoadingModal from 'components/common/Modals/LoadingModal'
import TextIconButton from 'components/common/Buttons/TextIconButton'
import UploadButton from 'components/common/Buttons/UploadButton'
import Text from 'components/common/Text'
import { isApp } from 'utils/userAgent'
import { useTranslation } from 'react-i18next'

import styles from './Start.module.css'

//const archive = `/${process.env.REACT_APP_VARIATION_PATH}/icons/archive.png`
const camera = `/${process.env.REACT_APP_VARIATION_PATH}/icons/camera.png`
const questionmark = `/${process.env.REACT_APP_VARIATION_PATH}/icons/questionmark.png`
const audioStart = `/${process.env.REACT_APP_VARIATION_PATH}/audio/start.mp3`

const Start = ({ isLoading, startSession, uploadError, warningModal, imgAmountWarn }) => {
    document.body.style.overflow = isLoading || uploadError || warningModal || imgAmountWarn ? 'hidden' : 'unset'

    const { t } = useTranslation()
    const showCameraButton = () => {
        const showTips = !(localStorage.getItem('showTips') === 'false')

        if (showTips) {
            return (
                <Link to="/tips">
                    <TextIconButton text={t('welcome.photo')} icon={camera} />
                </Link>
            )
        } else {
            return (
                <UploadButton
                    onChange={files => {
                        startSession(files[0])
                    }}
                    text={t('welcome.photo')}
                ></UploadButton>
            )
        }
    }

    /*
    const showArchive = () => {
        if (isApp()) {
            return (
                <a href="/archive">
                    <TextIconButton text={t('welcome.archive')} icon={archive} />
                </a>
            )
        }
    }
    */

    return (
        <>
            {isLoading && <LoadingModal></LoadingModal>}
            {uploadError && (
                <ErrorModal title={t('welcome.error')}>
                    <UploadButton
                        onChange={files => {
                            startSession(files[0])
                        }}
                        text={t('welcome.again')}
                    ></UploadButton>
                </ErrorModal>
            )}
            <div className={styles.wrapper}>
                <Text className={styles.text}>{t('welcome.done')}</Text>
                <div className={styles.links}>
                    {showCameraButton()}
                    <div className={styles.group}>
                        <Link to="/about">
                            <TextIconButton text={t('welcome.help')} icon={questionmark} />
                        </Link>
                        {/*showArchive()*/}
                    </div>
                </div>
            </div>
            {isApp() && (
                <Delayed delay={3000}>
                    <Audio source={audioStart} />
                </Delayed>
            )}
        </>
    )
}

export default Start
