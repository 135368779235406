import { compose } from 'redux'
import { connect } from 'react-redux'

import Overlay from './Overlay'
import { hideOverlays } from 'redux/actions/ui/hideOverlays'

const mapDispatchToProps = dispatch => ({
    hideOverlays: () => dispatch(hideOverlays()),
})

const mapStateToProps = (state, props) => ({
    ...props,
    ...state.ui,
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))
export default enhance(Overlay)
