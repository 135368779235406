import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import Text from 'components/common/Text'

import styles from './Steps.module.css'

const Steps = ({ className }) => {
    const { t } = useTranslation()
    return (
        <div className={cx(styles.wrapper, className)}>
            <div className={styles.container}>
                <Text className={styles.headline}>{t('steps.first')}</Text>
                <Text className={styles.text}>{t('steps.first.desc')}</Text>
            </div>
            <div className={styles.container}>
                <Text className={styles.headline}>{t('steps.second')}</Text>
                <Text className={styles.text}>{t('steps.second.desc')}</Text>
            </div>
            <div className={styles.container}>
                <Text className={styles.headline}>{t('steps.third')}</Text>
                <Text className={styles.text}>{t('steps.third.desc')}</Text>
            </div>
        </div>
    )
}

Steps.propTypes = {
    className: PropTypes.string,
}

export default Steps
