import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Box from 'components/common/Box'
import Overlay from 'components/common/Overlay'
import Text from 'components/common/Text'

import styles from './BasicModal.module.css'

const BasicModal = ({ children, className, title, ...props }) => (
    <Overlay className={styles.wrapper} {...props}>
        <Box className={cx(styles.box, className)}>
            {title && <Text className={styles.title}>{title}</Text>}
            {children}
        </Box>
    </Overlay>
)

BasicModal.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string,
}

export default BasicModal
