import { SHOW_SHOP_MODAL } from 'redux/actionTypes/ui/showShopModal'

export const showShopModal = () => {
    return dispatch => {
        dispatch(showModal())
    }
}

const showModal = () => ({
    type: SHOW_SHOP_MODAL,
})
