import { SHOW_WARNING_MODAL } from 'redux/actionTypes/ui/showWarningModal'

export const showWarningModal = () => {
    return dispatch => {
        dispatch(showModal())
    }
}

const showModal = () => ({
    type: SHOW_WARNING_MODAL,
})
