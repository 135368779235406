const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL

const appleAppStoreUrl = 'https://apps.apple.com/de/app/mitmalfilm/id1494120000'

const androidAppUrl = 'https://www.mitmalfilm.shop/mitmalfilm-android-app'

const shopUrl = 'https://www.mitmalfilm.shop/'

const startSessionUrl = `${baseUrl}/start`

const getPreviewImageUrl = (sessionId, index) => `${baseUrl}/sessions/${sessionId}/images/${index}.png`

const getPlaceholderImageUrl = (projectId, index) => `${baseUrl}/projects/${projectId}/placeholders/${index}.png`

const getJobStatusUrl = jobId => `${baseUrl}/jobs/${jobId}`

const getRenderingRequestUrl = sessionId => `${baseUrl}/sessions/${sessionId}/render`

const getAddImageUrl = sessionId => `${baseUrl}/sessions/${sessionId}/add`

export {
    appleAppStoreUrl,
    androidAppUrl,
    shopUrl,
    startSessionUrl,
    getPreviewImageUrl,
    getPlaceholderImageUrl,
    getJobStatusUrl,
    getRenderingRequestUrl,
    getAddImageUrl,
}
