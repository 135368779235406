import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { ConnectedRouter } from 'connected-react-router'

import routes from './routes'

import './App.css'

//tracking
if (!localStorage.getItem('uuid')) {
    localStorage.setItem('uuid', uuidv4())
}

const App = ({ context, history }) => {
    return (
        <ConnectedRouter history={history} context={context}>
            {routes}
        </ConnectedRouter>
    )
}

App.propTypes = {
    history: PropTypes.object,
}

export default App
