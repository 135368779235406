import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'

import Text from 'components/common/Text'
import TextIconButton from 'components/common/Buttons/TextIconButton'
import ProcessingAnimation from 'components/pages/rendering/ProcessingAnimation'

import styles from './FetchData.module.css'

const back = `/${process.env.REACT_APP_VARIATION_PATH}/icons/arrow-left.png`

class FetchData extends React.Component {
    constructor(props) {
        super(props)
        this.className = props.className
        this.text = props.text
        this.timer = null
        this.state = {
            timeout: false,
        }
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ timeout: true })
            this.timer = null
        }, 10000)
    }

    componentWillUnmount() {
        this.setState({ timeout: false })
        if (this.timer) {
            clearTimeout(this.timer)
        }
    }

    showButtons = () => {
        const { history } = this.props
        const { t } = this.props
        return (
            <div className={styles.buttons}>
                <Link to="/">
                    <TextIconButton text={t('general.back.home')} icon={back} />
                </Link>
                {history && (
                    <TextIconButton onClick={() => history.goBack()} text={t('general.back.overview')} icon={back} />
                )}
            </div>
        )
    }

    render() {
        return (
            <div className={cx(styles.wrapper, this.className)}>
                <div className={styles.processing}>
                    <ProcessingAnimation className={styles.animation} />
                    <Text className={styles.text}>{this.text}</Text>
                    {this.state.timeout && this.showButtons()}
                </div>
            </div>
        )
    }
}

FetchData.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
}

export default withTranslation()(FetchData)
