const userAgent = {
    appName: 'Mitmalfilm',
    appVersion: '1.0.0',
    appVariationName: 'Mitmalfilm',
    appVariationVersion: '1.0.0',
}

const android = () => navigator.userAgent.match(/Android/i)
const iOS = () => navigator.userAgent.match(/iOS|iPhone|iPad|iPod/i)
const isApp = () => navigator.userAgent.indexOf(userAgent.appName) >= 0

export { userAgent, android, iOS, isApp }
