import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import BackButton from 'components/common/Buttons/BackButton'
import Box from 'components/common/Box'
import Image from 'components/common/Image'

import styles from './Header.module.css'

const logo = `/${process.env.REACT_APP_VARIATION_PATH}/logo/logo.png`

const Header = ({ showWarningModal }) => {
    const location = useLocation()
    const { t } = useTranslation()
    const showBackButton =
        location.pathname === '/about' ||
        location.pathname === '/tips' ||
        location.pathname === '/archive' ||
        location.pathname === '/impressum'

    const showBackButtonWithWarning =
        location.pathname.startsWith('/sessions/') || location.pathname.startsWith('/jobs/')

    const hideLogo = location.pathname.startsWith('/jobs/')

    const getLink = () => {
        return location.pathname === '/impressum' ? '/about' : '/'
    }

    return (
        <div className={styles.wrapper}>
            {showBackButton && (
                <Link to={getLink()}>
                    <BackButton className={styles.back} />
                </Link>
            )}
            {showBackButtonWithWarning && <BackButton className={styles.back} onClick={() => showWarningModal()} />}
            {!hideLogo && (
                <div className={styles.logo}>
                    <Box className={styles.box}></Box>
                    <Image source={logo} altText={t('general.logo')} className={styles.image} />
                </div>
            )}
        </div>
    )
}

export default Header
