import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import Text from 'components/common/Text'

import styles from './Imprint.module.css'

const Imprint = () => {
    const { t } = useTranslation()
    window.scrollTo(0, 0)
    return (
        <div className={styles.wrapper}>
            <div id="imprint" className={styles.imprint}>
                <Text className={styles.title}>{t('imprint.title')}</Text>
                <Text className={styles.text} dangerouslySetInnerHTML={{ __html: t('imprint.content') }} />
            </div>
            <div id="privacy">
                <Text
                    className={cx(styles.text, styles.privacy)}
                    dangerouslySetInnerHTML={{ __html: t('imprint.privacy') }}
                />
            </div>
        </div>
    )
}

export default Imprint
