import React from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Audio from 'components/common/Audio'
import BasicModal from 'components/common/Modals/BasicModal'
import WarningModal from 'components/common/Modals/WarningModal'
import QuestionModal from 'components/common/Modals/QuestionModal'
import StoreLinks from 'components/common/Modals/QuestionModal/StoreLinks'
import TextIconButton from 'components/common/Buttons/TextIconButton'
import Text from 'components/common/Text'
import FetchData from 'components/pages/rendering/FetchData'
import ProgressBar from 'components/pages/rendering/ProgressBar'
import { FINISHED, FAILED, QUEUED, CANCELED } from 'constants/jobStatus'
import ProcessingAnimation from 'components/pages/rendering/ProcessingAnimation'
import RenderedVideo from 'components/pages/rendering/RenderedVideo'
import { isApp } from 'utils/userAgent'

import styles from './Rendering.module.css'

const appIcon = `/${process.env.REACT_APP_VARIATION_PATH}/icons/app.png`
//const archive = `/${process.env.REACT_APP_VARIATION_PATH}/icons/archive.png`
const back = `/${process.env.REACT_APP_VARIATION_PATH}/icons/arrow-left.png`
const next = `/${process.env.REACT_APP_VARIATION_PATH}/icons/arrow-right.png`
const download = `/${process.env.REACT_APP_VARIATION_PATH}/icons/download.png`
const share = `/${process.env.REACT_APP_VARIATION_PATH}/icons/share.png`

const audioRenderingStart = `/${process.env.REACT_APP_VARIATION_PATH}/audio/rendering-start.mp3`
const audioRenderingFinish = `/${process.env.REACT_APP_VARIATION_PATH}/audio/rendering-finish.mp3`

const Rendering = ({
    jobId,
    job,
    fetchSessionData,
    fetchJobStatus,
    session,
    basicModal,
    questionModal,
    showBasicModal,
    showQuestionModal,
    warningModal,
}) => {
    document.body.style.overflow = questionModal || basicModal || warningModal ? 'hidden' : 'unset'
    let history = useHistory()
    const { t } = useTranslation()

    if (!job) {
        fetchJobStatus(jobId)
        return (
            <>
                {warningModal && <WarningModal title={t('warning.back')}></WarningModal>}
                <FetchData text={t('rendering.loading')}></FetchData>
            </>
        )
    }

    if (!session) {
        fetchSessionData(job.sessionId)
        return (
            <>
                {warningModal && <WarningModal title={t('warning.back')}></WarningModal>}
                <FetchData text={t('rendering.processing')}></FetchData>
            </>
        )
    }

    if (job.status !== FINISHED) {
        setTimeout(() => fetchJobStatus(jobId), 1000)
    }

    const videoStreamUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/sessions/${job.sessionId}/video`
    const previewUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/sessions/${job.sessionId}/preview`
    const videoDownloadUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/sessions/${job.sessionId}/download`
    const downloadFileName = (process.env.REACT_APP_VARIATION_PATH || 'movie') + '.mp4'
    const shareUrl = `${process.env.REACT_APP_FRONTEND_SHARE_URL}/jobs/${jobId}/share`

    let output

    if (job.status === FINISHED) {
        output = (
            <div className={styles.finished}>
                {questionModal && (
                    <QuestionModal title={t('rendering.share')}>
                        <a href={shareUrl}>
                            <TextIconButton text={t('rendering.share')} icon={share} largeText={true} />
                        </a>
                    </QuestionModal>
                )}

                <div className={styles.video}>
                    <RenderedVideo videoUrl={videoStreamUrl} previewUrl={previewUrl} />
                </div>
                <div className={styles.buttons}>
                    {/* the download param is causing issues on ios */}
                    {isApp() && (
                        <a href={videoDownloadUrl}>
                            <TextIconButton text={t('rendering.download')} icon={download} largeText={true} />
                        </a>
                    )}
                    {!isApp() && (
                        <a href={videoDownloadUrl} download={downloadFileName}>
                            <TextIconButton text={t('rendering.download')} icon={download} largeText={true} />
                        </a>
                    )}
                    {isApp() && (
                        <TextIconButton
                            onClick={() => showQuestionModal()}
                            text={t('rendering.share')}
                            icon={share}
                            largeText={true}
                        />
                    )}
                    {/*isApp() && (
                        <a href="/archive">
                            <TextIconButton text={t('rendering.archive')} icon={archive} />
                        </a>
                    )*/}
                    {!isApp() && (
                        <TextIconButton onClick={() => showBasicModal()} text={t('rendering.getApp')} icon={appIcon} />
                    )}
                    <Link to="/">
                        <TextIconButton text={t('rendering.new')} icon={next} />
                    </Link>
                    {isApp() && <Audio source={audioRenderingFinish} />}
                </div>
            </div>
        )
    } else if (job.status === QUEUED) {
        output = (
            <div className={styles.processing}>
                <ProcessingAnimation className={styles.animation} />
                <Text className={styles.text}>
                    {t('rendering.queue')}
                    {job.queuePos ? t('rendering.queuePos', { queuePos: job.queuePos }) : ''}
                    ...
                </Text>
            </div>
        )
    } else if (job.status === FAILED || job.status === CANCELED) {
        output = (
            <div className={styles.processing}>
                <Text className={styles.processing}>{t('rendering.error')}</Text>
                <div className={styles.buttons}>
                    <TextIconButton onClick={() => history.goBack()} text={t('rendering.back')} icon={back} />
                    <Link to="/">
                        <TextIconButton text={t('rendering.new')} icon={next} />
                    </Link>
                </div>
            </div>
        )
    } else {
        output = (
            <>
                <div className={styles.processing}>
                    <ProcessingAnimation className={styles.animation} />
                    <Text className={styles.text}>{t('rendering.rendering')}</Text>
                    <ProgressBar progress={job.estimatedProgress} />
                </div>
                {isApp() && <Audio source={audioRenderingStart} />}
            </>
        )
    }

    return (
        <>
            {basicModal && (
                <BasicModal title={t('rendering.getApp')}>
                    <StoreLinks />
                </BasicModal>
            )}
            {warningModal && <WarningModal title={t('warning.back')}></WarningModal>}
            <div className={styles.wrapper}>{output}</div>
        </>
    )
}

Rendering.propTypes = {
    fetchJobStatus: PropTypes.func.isRequired,
    session: PropTypes.shape({
        id: PropTypes.string.isRequired,
        project: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            images: PropTypes.number.isRequired,
        }),
    }),
}

export default Rendering
