import React from 'react'
import { useTranslation } from 'react-i18next'

import Audio from 'components/common/Audio'
import Checkbox from 'components/common/Checkbox'
import ErrorModal from 'components/common/Modals/ErrorModal'
import Image from 'components/common/Image'
import LoadingModal from 'components/common/Modals/LoadingModal'
import UploadButton from 'components/common/Buttons/UploadButton'
import Text from 'components/common/Text'
import { isApp } from 'utils/userAgent'

import styles from './CapturingTips.module.css'

const distance = `/${process.env.REACT_APP_VARIATION_PATH}/illustrations/tip_distance.png`
const light = `/${process.env.REACT_APP_VARIATION_PATH}/illustrations/tip_light.png`
const audioTipps = `/${process.env.REACT_APP_VARIATION_PATH}/audio/tipps.mp3`

const Capture = ({ startSession, isLoading, uploadError, warningModal, imgAmountWarn }) => {
    document.body.style.overflow = isLoading || uploadError || warningModal || imgAmountWarn ? 'hidden' : 'unset'

    const { t } = useTranslation()
    return (
        <>
            {isLoading && <LoadingModal></LoadingModal>}
            {uploadError && (
                <ErrorModal title={t('tips.error')}>
                    <UploadButton
                        onChange={files => {
                            startSession(files[0])
                        }}
                        text={t('tips.again')}
                    ></UploadButton>
                </ErrorModal>
            )}
            <div className={styles.wrapper}>
                <Text className={styles.headline}>{t('tips.title')}</Text>
                <div className={styles.tips}>
                    <div className={styles.container}>
                        <Image source={distance} altText={t('tips.distance')} className={styles.distance} />
                        <Text className={styles.text}>{t('tips.tip1')}</Text>
                    </div>
                    <div className={styles.container}>
                        <Image source={light} altText={t('tips.lighting')} className={styles.light} />
                        <Text className={styles.text}>{t('tips.tip2')}</Text>
                    </div>
                    <div className={styles['checkbox-container']}>
                        <Checkbox className={styles.checkbox} text={t('tips.dismiss')} />
                    </div>
                </div>
                <UploadButton
                    className={styles.next}
                    onChange={files => {
                        startSession(files[0])
                    }}
                    text={t('welcome.photo')}
                ></UploadButton>
            </div>
            {isApp() && <Audio source={audioTipps} />}
        </>
    )
}

export default Capture
