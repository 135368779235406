import { push } from 'connected-react-router'
import axios from 'axios'

import { startSessionUrl } from 'utils/url'
import {
    START_SESSION_SUCCESS,
    START_SESSION_FAILURE,
    START_SESSION_STARTED,
} from 'redux/actionTypes/session/start'

export const startSession = file => {
    return dispatch => {
        const formData = new FormData()
        formData.append('image', file)

        dispatch(startSessionStarted())

        axios
            .post(startSessionUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    uuid: localStorage.getItem('uuid'),
                },
            })
            .then(res => {
                dispatch(startSessionSuccess(res.data))
                dispatch(push(`/sessions/${res.data.sessionId}`))
            })
            .catch(err => {
                dispatch(startSessionFailure(err.message))
            })
    }
}

const startSessionSuccess = data => ({
    type: START_SESSION_SUCCESS,
    payload: {
        ...data,
    },
})

const startSessionStarted = () => ({
    type: START_SESSION_STARTED,
})

const startSessionFailure = error => ({
    type: START_SESSION_FAILURE,
    payload: {
        error,
    },
})
