import { HIDE_OVERLAYS } from 'redux/actionTypes/ui/hideOverlays'

export const hideOverlays = () => {
    return dispatch => {
        dispatch(closeModals())
    }
}

const closeModals = () => ({
    type: HIDE_OVERLAYS,
})
