import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const pngs = [
    require('./files/mmf_pausenanimation_small_000.png'),
    require('./files/mmf_pausenanimation_small_001.png'),
    require('./files/mmf_pausenanimation_small_002.png'),
    require('./files/mmf_pausenanimation_small_003.png'),
    require('./files/mmf_pausenanimation_small_004.png'),
]

class ProcessingAnimation extends React.Component {
    constructor(props) {
        super(props)
        this.className = props.className

        this.interval = null
        this.frame = 0
        this.img = pngs[this.frame]
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.animation()
        }, 1000 / pngs.length)
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    animation() {
        ++this.frame
        if (this.frame >= pngs.length) {
            this.frame = 0
        }
        this.img = pngs[this.frame]

        this.forceUpdate()
    }

    render() {
        const { t } = this.props
        return <img alt={t('general.rendering')} className={this.className} src={this.img} />
    }
}

ProcessingAnimation.propTypes = {
    className: PropTypes.string,
}

export default withTranslation()(ProcessingAnimation)
