import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Text from 'components/common/Text'

import styles from './ProgressBar.module.css'

const ProgressBar = ({ className, progress }) => {
    const getProgressClass = () => {
        if (progress < 10) {
            return styles.background
        } else if (progress < 25) {
            return styles.background_10
        } else if (progress < 50) {
            return styles.background_25
        } else if (progress < 66) {
            return styles.background_50
        } else if (progress < 90) {
            return styles.background_66
        } else if (progress < 99) {
            return styles.background_90
        } else if (progress < 100) {
            return styles.background_99
        } else {
            return styles.background_100
        }
    }

    return (
        <div className={cx(styles.wrapper, className)}>
            <Text className={styles.label}>{Math.min(progress, 100)}%</Text>
            <div className={cx(styles.background, getProgressClass())}></div>
            <div className={cx(styles.hidden, styles.background_10)}></div>
            <div className={cx(styles.hidden, styles.background_25)}></div>
            <div className={cx(styles.hidden, styles.background_50)}></div>
            <div className={cx(styles.hidden, styles.background_66)}></div>
            <div className={cx(styles.hidden, styles.background_90)}></div>
            <div className={cx(styles.hidden, styles.background_99)}></div>
            <div className={cx(styles.hidden, styles.background_100)}></div>
        </div>
    )
}

ProgressBar.propTypes = {
    className: PropTypes.string,
    progress: PropTypes.number.isRequired,
}

export default ProgressBar
