import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import FilePicker from 'components/common/FilePicker'
import TextIconButton from 'components/common/Buttons/TextIconButton'

import styles from './UploadButton.module.css'

const camera = `/${process.env.REACT_APP_VARIATION_PATH}/icons/camera.png`

const UploadButton = ({ className, text, onChange, ...props }) => (
    <FilePicker className={cx(styles.wrapper, className)} onChange={onChange} {...props}>
        <TextIconButton text={text} icon={camera} />
    </FilePicker>
)

UploadButton.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
}

export default UploadButton
