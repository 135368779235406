import React from 'react'
import PropTypes from 'prop-types'
import ReactCompareImage from 'react-compare-image'
import { useTranslation } from 'react-i18next'

import Box from 'components/common/Box'
import FilePicker from 'components/common/FilePicker'
import Image from 'components/common/Image'
import Spinner from 'components/common/Spinner'

import styles from './ImagePicker.module.css'

const cameraBig = `/${process.env.REACT_APP_VARIATION_PATH}/icons/camera_big.png`
const cameraSmall = `/${process.env.REACT_APP_VARIATION_PATH}/icons/camera_small.png`

const ImagePicker = ({ sessionId, isUploaded, loading, addImage, previewUrl, placeholderUrl }) => {
    const { t } = useTranslation()

    const UPLOAD = 'upload'
    const REPLACE = 'replace'
    const buttonType = isUploaded ? REPLACE : UPLOAD
    const image = isUploaded ? cameraSmall : cameraBig

    const handleFiles = files => {
        const [file] = files
        if (file) addImage(sessionId, file)
    }

    const getImageComponent = () => {
        if (isUploaded) {
            return <ReactCompareImage leftImage={placeholderUrl} rightImage={previewUrl} sliderLineColor="#5f5f5f" />
        }
        return (
            <div className={styles.placeholder}>
                <img src={placeholderUrl} alt={t('welcome.preview')} />
            </div>
        )
    }

    return (
        <div>
            {loading ? (
                <Box className={styles.box}>
                    <Spinner className={styles.spinner} />
                </Box>
            ) : (
                <Box className={styles.box}>
                    <FilePicker className={styles[buttonType]} onChange={handleFiles}>
                        <Box className={styles.camera}>
                            <Image source={image} altText={t('welcome.camera')} />
                        </Box>
                    </FilePicker>
                    {getImageComponent()}
                </Box>
            )}
        </div>
    )
}

ImagePicker.propTypes = {
    imageIndex: PropTypes.number.isRequired,
    projectId: PropTypes.string.isRequired,
    sessionId: PropTypes.string.isRequired,
    addImage: PropTypes.func.isRequired,
    isUploaded: PropTypes.bool,
    loading: PropTypes.bool,
}

export default ImagePicker
