import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import Box from 'components/common/Box'
import Image from 'components/common/Image'

import styles from './NextButton.module.css'

const next = `/${process.env.REACT_APP_VARIATION_PATH}/icons/arrow-right.png`

const NextButton = ({ className, ...props }) => {
    const { t } = useTranslation()

    return (
        <Box className={cx(styles.wrapper, className)} {...props}>
            <div className={styles.icon}>
                <Image source={next} altText={t('addImages.next')} />
            </div>
        </Box>
    )
}

NextButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
}

export default NextButton
