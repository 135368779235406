import { connect } from 'react-redux'

import SessionProgressDescription from 'components/common/SessionProgressDescription'

const mapStateToProps = (state, props) => ({
    ...props,
    title: state.session.project.title,
    total: state.session.project.images,
    uploaded: Object.keys(state.session.uploadedImages).length,
})

export default connect(mapStateToProps)(SessionProgressDescription)
