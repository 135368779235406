import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import Image from 'components/common/Image'
import QuestionModal from 'components/common/Modals/QuestionModal'
import TextIconButton from 'components/common/Buttons/TextIconButton'
import { iOS } from 'utils/userAgent'
import { shopUrl } from 'utils/url'
import { isApp } from 'utils/userAgent'

import styles from './Links.module.css'

const download = `/${process.env.REACT_APP_VARIATION_PATH}/icons/download.png`
const next = `/${process.env.REACT_APP_VARIATION_PATH}/icons/arrow-right.png`
const book = `/${process.env.REACT_APP_VARIATION_PATH}/illustrations/book.jpg`
const sample = `/${process.env.REACT_APP_VARIATION_PATH}/sample/sample.jpg`

const Links = ({ showShopModal, shopModal, className }) => {
    const { t } = useTranslation()
    const samplePDF = `/${process.env.REACT_APP_VARIATION_PATH}/sample/${t('rendering.sample' + (iOS() ? '.ios' : ''))}`
    return (
        <>
            {shopModal && (
                <QuestionModal>
                    <a href={shopUrl} target="_blank" rel="noopener noreferrer">
                        <TextIconButton text={t('links.goto')} icon={next} />
                    </a>
                </QuestionModal>
            )}
            <div className={cx(styles.wrapper, className)}>
                <div className={styles.sample}>
                    <div className={styles.image}>
                        <img src={sample} alt={t('links.free')} />
                    </div>
                    <a href={samplePDF}>
                        <TextIconButton text={t('links.download')} icon={download} largeText={true} />
                    </a>
                </div>
                <div className={styles.book}>
                    <div className={styles.cover}>
                        <Image source={book} altText={t('links.book')} />
                    </div>
                    {isApp() && (
                        <TextIconButton
                            onClick={() => {
                                showShopModal()
                            }}
                            text={t('links.goto')}
                            icon={next}
                        />
                    )}
                    {!isApp() && (
                        <a href={shopUrl} target="_blank" rel="noopener noreferrer">
                            <TextIconButton text={t('links.goto')} icon={next} />
                        </a>
                    )}
                </div>
            </div>
        </>
    )
}

Links.propTypes = {
    className: PropTypes.string,
}

export default Links
