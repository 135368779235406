import axios from 'axios'

import { getJobStatusUrl } from 'utils/url'
import {
    FETCH_STATUS_FAILURE,
    FETCH_STATUS_STARTED,
    FETCH_STATUS_SUCCESS,
} from 'redux/actionTypes/job/fetchStatus'

export const fetchJobStatus = id => {
    return dispatch => {
        dispatch(fetchJobStatusStarted())
        axios
            .get(getJobStatusUrl(id), {
                headers: { uuid: localStorage.getItem('uuid') },
            })
            .then(res => {
                dispatch(fetchJobStatusSuccess(res.data))
            })
            .catch(err => {
                dispatch(fetchJobStatusFailure(err.message))
            })
    }
}

const fetchJobStatusSuccess = data => ({
    type: FETCH_STATUS_SUCCESS,
    payload: {
        ...data,
    },
})

const fetchJobStatusStarted = () => ({
    type: FETCH_STATUS_STARTED,
})

const fetchJobStatusFailure = error => ({
    type: FETCH_STATUS_FAILURE,
    payload: {
        error,
    },
})
