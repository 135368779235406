import { connect } from 'react-redux'

import ImagePicker from 'components/common/ImagePicker'
import { addImage } from 'redux/actions/session/addImage'
import { getPlaceholderImageUrl } from 'utils/url'

const mapDispatchToProps = dispatch => ({
    addImage: (id, file) => dispatch(addImage(id, file)),
})

const mapStateToProps = (state, props) => ({
    ...props,
    previewUrl:
        process.env.REACT_APP_BACKEND_BASE_URL +
        state.session.croppedImageUrls[props.imageIndex],
    placeholderUrl: getPlaceholderImageUrl(props.projectId, props.imageIndex),
    isUploaded: state.session.uploadedImages.hasOwnProperty(props.imageIndex),
})

export default connect(mapStateToProps, mapDispatchToProps)(ImagePicker)
