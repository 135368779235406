import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import mitmalfilm_de from './translations/mitmalfilm_de'
import example_de from './translations/example_de'
import mitmalfilm_en from './translations/mitmalfilm_en'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    mitmalfilm_de: {
        translation: mitmalfilm_de,
    },
    mitmalfilm_en: {
        translation: mitmalfilm_en,
    },
    example_de: {
        translation: example_de,
    },
}

// map array translation items to strings
// eslint-disable-next-line
for (const res in resources) {
    const trans = resources[res].translation
    // eslint-disable-next-line
    for (const lngKey in trans) {
        if (trans[lngKey] instanceof Array && trans[lngKey].length > 0 && !(trans[lngKey][0] instanceof Object)) {
            trans[lngKey] = trans[lngKey].join('\n')
        }
    }
}

i18n.use(initReactI18next).init({
    resources,
    lng: process.env.REACT_APP_VARIATION + '_' + (process.env.REACT_APP_LANG ? process.env.REACT_APP_LANG : 'de'),

    keySeparator: false,

    interpolation: {
        escapeValue: false,
    },
})

export default i18n
