import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import session from './session'
import job from './job'
import ui from './ui'

const createRootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        session,
        job,
        ui,
    })

export default createRootReducer
