import axios from 'axios'

import {
    ADD_IMAGE_FAILURE,
    ADD_IMAGE_STARTED,
    ADD_IMAGE_SUCCESS,
} from 'redux/actionTypes/session/addImage'
import { getAddImageUrl } from 'utils/url'

export const addImage = (sessionId, file) => {
    return dispatch => {
        const formData = new FormData()
        formData.append('image', file)

        dispatch(addImageStarted())

        axios
            .post(getAddImageUrl(sessionId), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    uuid: localStorage.getItem('uuid'),
                },
            })
            .then(res => {
                dispatch(addImageSuccess(res.data))
            })
            .catch(err => {
                dispatch(addImageFailure(err.message))
            })
    }
}

const addImageSuccess = data => ({
    type: ADD_IMAGE_SUCCESS,
    payload: {
        ...data,
    },
})

const addImageStarted = () => ({
    type: ADD_IMAGE_STARTED,
})

const addImageFailure = error => ({
    type: ADD_IMAGE_FAILURE,
    payload: {
        error,
    },
})
