import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Box from 'components/common/Box'
import Image from 'components/common/Image'
import Text from 'components/common/Text'

import styles from './TextIconButton.module.css'

const TextIconButton = ({ className, icon, text, largeText, ...other }) => (
    <Box className={cx(styles.wrapper, className)} {...other}>
        <Text className={largeText ? styles['text-large'] : styles.text}>{text}</Text>
        <div className={styles.icon}>
            <Image source={icon} altText={text} />
        </div>
    </Box>
)

TextIconButton.propTypes = {
    className: PropTypes.string,
    text: PropTypes.node.isRequired,
    icon: PropTypes.node.isRequired,
    largeText: PropTypes.bool,
    onClick: PropTypes.func,
}

export default TextIconButton
