import { compose } from 'redux'
import { connect } from 'react-redux'

import Links from './Links'
import { showShopModal } from 'redux/actions/ui/showShopModal'

const mapDispatchToProps = dispatch => ({
    showShopModal: () => dispatch(showShopModal()),
})

const mapStateToProps = (state, props) => ({
    ...props,
    ...state.ui.overlay,
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(Links)
