import { SHOW_QUESTION_MODAL } from 'redux/actionTypes/ui/showQuestionModal'

export const showQuestionModal = () => {
    return dispatch => {
        dispatch(showModal())
    }
}

const showModal = () => ({
    type: SHOW_QUESTION_MODAL,
})
