import { START_SESSION_SUCCESS } from 'redux/actionTypes/session/start'
import { FETCH_SUCCESS } from 'redux/actionTypes/session/fetch'
import { ADD_IMAGE_SUCCESS } from 'redux/actionTypes/session/addImage'

const session = (state = null, action) => {
    switch (action.type) {
        case FETCH_SUCCESS:
        case START_SESSION_SUCCESS:
        case ADD_IMAGE_SUCCESS:
            const {
                project,
                sessionId,
                uploadedImages,
                croppedImageUrls,
                requiredImages,
                lastAddedImage,
                jobId,
            } = action.payload
            return {
                id: sessionId,
                project,
                uploadedImages,
                croppedImageUrls,
                requiredImages,
                lastAddedImage,
                jobId,
            }

        default:
            return state
    }
}

export default session
