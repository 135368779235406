import { compose } from 'redux'
import { connect } from 'react-redux'

import WarningModal from './WarningModal'
import { hideOverlays } from 'redux/actions/ui/hideOverlays'

const mapDispatchToProps = {
    hideOverlays: () => dispatch => dispatch(hideOverlays()),
}

const mapStateToProps = (state, props) => ({
    ...props,
    ...state.ui,
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))
export default enhance(WarningModal)
