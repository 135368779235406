import { START_SESSION_FAILURE, START_SESSION_STARTED, START_SESSION_SUCCESS } from 'redux/actionTypes/session/start'
import { ADD_IMAGE_STARTED, ADD_IMAGE_SUCCESS, ADD_IMAGE_FAILURE } from 'redux/actionTypes/session/addImage'
import { HIDE_OVERLAYS } from 'redux/actionTypes/ui/hideOverlays'
import { SHOW_BASIC_MODAL } from 'redux/actionTypes/ui/showBasicModal'
import { SHOW_QUESTION_MODAL } from 'redux/actionTypes/ui/showQuestionModal'
import { SHOW_SHOP_MODAL } from 'redux/actionTypes/ui/showShopModal'
import { SHOW_WARNING_MODAL } from 'redux/actionTypes/ui/showWarningModal'
import { REQUEST_RENDERING_STARTED, REQUEST_RENDERING_AMOUNT_WARNING } from 'redux/actionTypes/session/requestRendering'

const initialState = {
    startSession: {
        isLoading: false,
        uploadError: false,
    },
    addImages: {
        imgAmountWarn: false,
    },
    overlay: {
        basicModal: false,
        questionModal: false,
        shopModal: false,
        warningModal: false,
    },
}

const session = (state = initialState, action) => {
    switch (action.type) {
        case START_SESSION_STARTED:
            return {
                ...state,
                startSession: {
                    ...state.startSession,
                    isLoading: true,
                    uploadError: false,
                },
            }
        case START_SESSION_SUCCESS:
            return {
                ...state,
                startSession: {
                    ...state.startSession,
                    isLoading: false,
                    uploadError: false,
                },
            }
        case START_SESSION_FAILURE:
            return {
                ...state,
                startSession: {
                    ...state.startSession,
                    isLoading: false,
                    uploadError: true,
                },
            }
        case ADD_IMAGE_STARTED:
            return {
                ...state,
                addImages: {
                    isLoading: true,
                    uploadError: false,
                    imgAmountWarn: false,
                },
            }
        case ADD_IMAGE_FAILURE:
            return {
                ...state,
                addImages: {
                    isLoading: false,
                    uploadError: true,
                    imgAmountWarn: false,
                },
            }
        case ADD_IMAGE_SUCCESS:
            return {
                ...state,
                addImages: {
                    isLoading: false,
                    uploadError: false,
                    imgAmountWarn: false,
                },
            }
        case HIDE_OVERLAYS:
            return {
                ...state,
                addImages: {
                    isLoading: false,
                    uploadError: false,
                    imgAmountWarn: false,
                },
                startSession: {
                    isLoading: false,
                    uploadError: false,
                },
                overlay: {
                    basicModal: false,
                    questionModal: false,
                    shopModal: false,
                    warningModal: false,
                },
            }
        case SHOW_BASIC_MODAL:
            return {
                ...state,
                overlay: {
                    basicModal: true,
                },
            }
        case SHOW_QUESTION_MODAL:
            return {
                ...state,
                overlay: {
                    questionModal: true,
                },
            }
        case SHOW_SHOP_MODAL:
            return {
                ...state,
                overlay: {
                    shopModal: true,
                },
            }
        case SHOW_WARNING_MODAL:
            return {
                ...state,
                overlay: {
                    warningModal: true,
                },
            }
        case REQUEST_RENDERING_STARTED:
            return {
                ...state,
                addImages: {
                    isLoading: false,
                    uploadError: false,
                    imgAmountWarn: false,
                },
            }
        case REQUEST_RENDERING_AMOUNT_WARNING:
            return {
                ...state,
                addImages: {
                    isLoading: false,
                    uploadError: false,
                    imgAmountWarn: true,
                },
            }
        default:
            return state
    }
}

export default session
