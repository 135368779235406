import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Text from 'components/common/Text'

import styles from './Checkbox.module.css'

class Checkbox extends React.Component {
    constructor(props) {
        super(props)
        this.className = props.className
        this.text = props.text
        this.state = {
            showTips: true,
        }
    }

    componentDidMount() {
        localStorage.setItem('showTips', this.state.showTips)
    }

    toggleTips = () => {
        this.setState({ showTips: !this.state.showTips }, () => {
            localStorage.setItem('showTips', this.state.showTips)
        })
    }

    render() {
        return (
            <div
                className={cx(styles.wrapper, this.className)}
                onClick={this.toggleTips}
            >
                <div className={styles.checkbox}>
                    <div
                        className={
                            this.state.showTips
                                ? styles.unchecked
                                : styles.checked
                        }
                    ></div>
                </div>
                <Text className={styles.text}>{this.text}</Text>
            </div>
        )
    }
}

Checkbox.propTypes = {
    className: PropTypes.string,
    text: PropTypes.node.isRequired,
}

export default Checkbox
