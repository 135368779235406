import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Box.module.css'

const Box = ({ children, className, ...props }) => (
    <div className={cx(styles.box, className)} {...props}>
        {children}
    </div>
)

Text.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
}

export default Box
