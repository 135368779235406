import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Overlay from 'components/common/Overlay'
import Spinner from 'components/common/Spinner'
import Text from 'components/common/Text'

import styles from './LoadingModal.module.css'

const LoadingModal = ({ className, text, ...props }) => (
    <Overlay className={styles.wrapper} {...props}>
        <div className={styles.content}>
            <Spinner className={cx(styles.spinner, className)}></Spinner>
            {text && <Text className={styles.text}>{text}</Text>}
        </div>
    </Overlay>
)

LoadingModal.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
}

export default LoadingModal
