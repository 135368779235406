import React from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch } from 'react-router'

import Start from 'pages/Start'
import About from 'pages/About'
import CapturingTips from 'pages/CapturingTips'
import AddImages from 'pages/AddImages'
import Rendering from 'pages/Rendering'
import Archive from 'pages/Archive'
import Imprint from 'pages/Imprint'

const routes = (
    <>
        <Helmet>
            <title>Mitmalfilm</title>
            <meta name="description" content="Mitmalfilm" />
        </Helmet>
        <Switch>
            <Route exact path="/" component={Start} />
            <Route path="/about" component={About} />
            <Route path="/tips" component={CapturingTips} />
            <Route path="/sessions/:id" component={AddImages} />
            <Route path="/jobs/:id" component={Rendering} />
            <Route path="/archive" component={Archive} />
            <Route path="/impressum" component={Imprint} />
            <Route component={Start} />
        </Switch>
    </>
)

export default routes
