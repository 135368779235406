import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import Box from 'components/common/Box'

import { appleAppStoreUrl, androidAppUrl } from 'utils/url'

import styles from './StoreLinks.module.css'

const apple = `/${process.env.REACT_APP_VARIATION_PATH}/badges/apple.png`
const android = `/${process.env.REACT_APP_VARIATION_PATH}/badges/android.png`

const StoreLinks = ({ className, ...other }) => {
    const { t } = useTranslation()
    return (
        <div className={cx(styles.wrapper, className)} {...other}>
            <a href={appleAppStoreUrl} target="_blank" rel="noopener noreferrer">
                <Box className={styles.link}>
                    <img src={apple} alt={t('question.apple')} />
                </Box>
            </a>
            <a href={androidAppUrl} target="_blank" rel="noopener noreferrer">
                <Box className={styles.link}>
                    <img src={android} alt={t('question.android')} />
                </Box>
            </a>
        </div>
    )
}

StoreLinks.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
}

export default StoreLinks
