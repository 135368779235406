import { compose } from 'redux'
import { connect } from 'react-redux'

import About from './About'
import withHeader from 'hocs/withHeader'
import { showBasicModal } from 'redux/actions/ui/showBasicModal'
import { hideOverlays } from 'redux/actions/ui/hideOverlays'

const mapDispatchToProps = dispatch => ({
    showBasicModal: () => dispatch(showBasicModal()),
    hideOverlays: () => dispatch(hideOverlays()),
})

const mapStateToProps = (state, props) => ({
    ...props,
    ...state.ui.overlay,
    ...state.ui.hideOverlays,
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))

export default enhance(withHeader(About))
