import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import BackButton from 'components/common/Buttons/BackButton/BackButton'
import Image from 'components/common/Image'
import BasicModal from 'components/common/Modals/BasicModal'
import StoreLinks from 'components/common/Modals/QuestionModal/StoreLinks'
import TextIconButton from 'components/common/Buttons/TextIconButton'
import Links from 'components/pages/about/Links'
import Steps from 'components/pages/about/Steps'
import Text from 'components/common/Text'
import TutorialVideo from 'components/pages/about/TutorialVideo'

import { isApp } from 'utils/userAgent'
import styles from './About.module.css'

const appIcon = `/${process.env.REACT_APP_VARIATION_PATH}/icons/app.png`
const illustration = `/${process.env.REACT_APP_VARIATION_PATH}/illustrations/illustration.jpg`

class About extends React.Component {
    componentDidMount() {
        this.props = { ...this.props }
    }

    componentWillUnmount() {
        this.props.hideOverlays()
    }

    render() {
        document.body.style.overflow = this.props.basicModal || this.props.shopModal ? 'hidden' : 'unset'
        const { t } = this.props
        return (
            <>
                {this.props.basicModal && (
                    <BasicModal title={t('about.app')}>
                        <StoreLinks />
                    </BasicModal>
                )}
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <TutorialVideo className={styles.video} />
                        <div className={styles.illustration}>
                            <Image source={illustration} altText={t('about.illustration')} />
                        </div>
                        <Steps className={styles.steps} />
                        <Links className={styles.links} />
                    </div>

                    {!isApp() && (
                        <div className={styles.app}>
                            <TextIconButton
                                onClick={() => {
                                    this.props.showBasicModal()
                                }}
                                text={t('about.app')}
                                icon={appIcon}
                            />
                        </div>
                    )}

                    {!isApp() && (
                        <div className={styles.link}>
                            <Link to="/impressum">
                                <Text className={styles.text}>{t('about.legal')}</Text>
                            </Link>
                        </div>
                    )}
                </div>
                <div className={styles.back}>
                    <Link to="/">
                        <BackButton />
                    </Link>
                </div>
            </>
        )
    }
}

export default withTranslation()(About)
