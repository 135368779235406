import { compose } from 'redux'
import { connect } from 'react-redux'

import AddImages from './AddImages'
import withHeader from 'hocs/withHeader'
import { addImage } from 'redux/actions/session/addImage'
import { fetchSessionData } from 'redux/actions/session/fetch'
import { requestRendering } from 'redux/actions/session/requestRendering'
import { hideOverlays } from 'redux/actions/ui/hideOverlays'

const mapDispatchToProps = {
    onNext: () => (dispatch, getState) => dispatch(requestRendering(getState().session.id)),
    addImage: (id, file) => dispatch => dispatch(addImage(id, file)),
    fetchSessionData: id => dispatch => dispatch(fetchSessionData(id)),
    requestRendering: id => dispatch => dispatch(requestRendering(id)),
    hideOverlays: () => dispatch => dispatch(hideOverlays()),
}

const mapStateToProps = (state, props) => ({
    ...props,
    ...state.session,
    ...state.ui.overlay,
    ...state.ui.addImages,
    ...state.ui.hideOverlays,
    sessionId: props.match.params.id,
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withHeader)
export default enhance(AddImages)
