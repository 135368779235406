import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import Box from 'components/common/Box'
import Image from 'components/common/Image'

import styles from './BackButton.module.css'

const back = `/${process.env.REACT_APP_VARIATION_PATH}/icons/arrow-left.png`

const BackButton = ({ className, ...other }) => {
    const { t } = useTranslation()

    return (
        <Box className={cx(styles.wrapper, className)} {...other}>
            <div className={styles.icon}>
                <Image source={back} altText={t('general.back')} />
            </div>
        </Box>
    )
}

BackButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
}

export default BackButton
