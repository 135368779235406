import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Overlay.module.css'

const Overlay = ({ children, className, hideOverlays }) => (
    <div className={styles.wrapper}>
        <div className={cx(styles.overlay, className)} onClick={() => hideOverlays()}></div>
        {children}
    </div>
)

Overlay.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
}

export default Overlay
