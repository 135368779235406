import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'

import Box from 'components/common/Box'
import Overlay from 'components/common/Overlay'
import Text from 'components/common/Text'

import styles from './QuestionModal.module.css'

class QuestionModal extends React.Component {
    constructor(props) {
        super(props)
        this.className = props.className
        this.children = props.children
        this.state = {
            answer: '',
            question: this.getQuestion(),
            unlocked: false,
        }
    }

    checkAnswer = e => {
        const answer = this.state.question.answer
        this.setState({ answer: e.target.value }, () => {
            this.setState({
                unlocked: answer.indexOf(this.state.answer.toString().toLowerCase()) >= 0,
            })
        })
    }

    getQuestion = () => {
        const { t } = this.props

        let questions = t('question.questions', { returnObjects: true })
        return questions[Math.floor(Math.random() * questions.length)]
    }

    submitHandler = e => {
        e.preventDefault()
    }

    render() {
        const { t } = this.props
        return (
            <Overlay className={styles.wrapper}>
                <Box className={cx(styles.box, this.className)}>
                    <Text className={styles.title}>{t('question.wait')}</Text>
                    <Text className={styles.text}>
                        {t('question.question', { question: this.state.question.text })}
                    </Text>
                    <form onSubmit={e => this.submitHandler(e)}>
                        <input
                            className={styles.input}
                            onChange={e => this.checkAnswer(e)}
                            type="text"
                            value={this.state.answer}
                        />
                    </form>
                    {this.state.unlocked && <div>{this.children}</div>}
                </Box>
            </Overlay>
        )
    }
}

QuestionModal.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    children: PropTypes.node,
}

export default withTranslation()(QuestionModal)
