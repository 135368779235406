import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { isMobileLandscape, isTabletPortrait, isTabletLandscape, isDesktop } from 'utils/breakpoints'

import styles from './Image.module.css'

const Image = ({ className, source, altText }) => {
    const sm = '_min-480'
    const md = '_481-960'
    const lg = '_961-1280'
    const xl = '_1281-max'

    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)

    useEffect(() => {
        const updateDimensions = () => {
            setWidth(window.innerWidth)
            setHeight(window.innerHeight)
        }
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getSize = source => {
        if (isMobileLandscape(width, height)) return getImage(source, sm)
        else if (isTabletPortrait(width, height)) return getImage(source, md)
        else if (isTabletLandscape(width, height)) return getImage(source, lg)
        else if (isDesktop(width, height)) return getImage(source, xl)
        return source
    }

    const getImage = (source, size) => {
        const fileName = source.split('.')[0]
        const extension = source.split('.')[1]
        return `${fileName}${size}.${extension}`
    }

    const getSrcSet = source => {
        const image = getSize(source)
        return `${image} 1x, ${getImage(image, '@2x')} 2x, ${getImage(image, '@3x')} 3x`
    }

    return (
        <img src={getSize(source)} srcSet={getSrcSet(source)} alt={altText} className={cx(styles.image, className)} />
    )
}

Image.propTypes = {
    className: PropTypes.string,
    source: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
}

export default Image
