import { FETCH_STATUS_SUCCESS } from 'redux/actionTypes/job/fetchStatus'
import { REQUEST_RENDERING_SUCCESS } from 'redux/actionTypes/session/requestRendering'

const job = (state = null, action) => {
    switch (action.type) {
        case REQUEST_RENDERING_SUCCESS:
            return {
                ...action.payload,
            }
        case FETCH_STATUS_SUCCESS:
            return {
                ...action.payload,
            }
        default:
            return state
    }
}

export default job
