import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from './Header'
import { showWarningModal } from 'redux/actions/ui/showWarningModal'

const mapDispatchToProps = dispatch => ({
    showWarningModal: () => dispatch(showWarningModal()),
})

const mapStateToProps = (state, props) => ({
    ...props,
    ...state.ui,
})

const enhance = compose(connect(mapStateToProps, mapDispatchToProps))
export default enhance(Header)
