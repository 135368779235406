import { compose } from 'redux'
import { connect } from 'react-redux'

import Start from './Start'
import withHeader from 'hocs/withHeader'
import { startSession } from 'redux/actions/session/start'

const mapDispatchToProps = dispatch => ({
    startSession: file => dispatch(startSession(file)),
})

const mapStateToProps = (state, props) => ({
    ...props,
    ...state.ui.startSession,
})

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHeader
)
export default enhance(Start)
