const MOBILE = 480
const TABLET = 960
const DESKTOP = 1280

const isMobileLandscape = (width, height) => width > MOBILE && height <= MOBILE
const isTabletPortrait = (width, height) => width > MOBILE && width <= TABLET && height > MOBILE
const isTabletLandscape = (width, height) => width > TABLET && width <= DESKTOP && height > MOBILE
const isDesktop = (width, height) => width > DESKTOP && height > MOBILE

export { isMobileLandscape, isTabletPortrait, isTabletLandscape, isDesktop }
