import { SHOW_BASIC_MODAL } from 'redux/actionTypes/ui/showBasicModal'

export const showBasicModal = () => {
    return dispatch => {
        dispatch(showModal())
    }
}

const showModal = () => ({
    type: SHOW_BASIC_MODAL,
})
