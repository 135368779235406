import axios from 'axios'

import { FETCH_FAILURE, FETCH_STARTED, FETCH_SUCCESS } from 'redux/actionTypes/session/fetch'

export const fetchSessionData = id => {
    return dispatch => {
        dispatch(fetchSessionStarted())
        const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/sessions/${id}`

        axios
            .get(url, {
                headers: {
                    uuid: localStorage.getItem('uuid'),
                },
            })
            .then(res => {
                dispatch(fetchSessionSuccess(res.data))
            })
            .catch(err => {
                dispatch(fetchSessionFailure(err.message))
            })
    }
}

const fetchSessionSuccess = data => ({
    type: FETCH_SUCCESS,
    payload: {
        ...data,
    },
})

const fetchSessionStarted = () => ({
    type: FETCH_STARTED,
})

const fetchSessionFailure = error => ({
    type: FETCH_FAILURE,
    payload: {
        error,
    },
})
