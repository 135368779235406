import { push } from 'connected-react-router'
import axios from 'axios'

import { getRenderingRequestUrl } from 'utils/url'
import {
    REQUEST_RENDERING_FAILURE,
    REQUEST_RENDERING_STARTED,
    REQUEST_RENDERING_SUCCESS,
    REQUEST_RENDERING_AMOUNT_WARNING,
} from 'redux/actionTypes/session/requestRendering'

export const requestRendering = ({ sessionId, project, croppedImageUrls, force }) => {
    return dispatch => {
        if (!force && Object.keys(croppedImageUrls).length !== project.images) {
            return dispatch(requestRenderingAmountWarning())
        }

        dispatch(requestRenderingStarted())

        axios
            .get(getRenderingRequestUrl(sessionId), {
                headers: { uuid: localStorage.getItem('uuid') },
            })
            .then(res => {
                dispatch(requestRenderingSuccess(res.data))
                dispatch(push(`/jobs/${res.data.id}`))
            })
            .catch(err => {
                dispatch(requestRenderingFailure(err.message))
            })
    }
}

const requestRenderingSuccess = data => ({
    type: REQUEST_RENDERING_SUCCESS,
    payload: {
        ...data,
    },
})

const requestRenderingStarted = () => ({
    type: REQUEST_RENDERING_STARTED,
})

const requestRenderingFailure = error => ({
    type: REQUEST_RENDERING_FAILURE,
    payload: {
        error,
    },
})

const requestRenderingAmountWarning = () => ({
    type: REQUEST_RENDERING_AMOUNT_WARNING,
})
