import React from 'react'

import Header from 'components/common/Header'

export default function withHeaderHOC(WrappedComponent) {
    class WithHeader extends React.Component {
        render() {
            const { ...other } = this.props
            return (
                <>
                    <Header />
                    <WrappedComponent {...other} />
                </>
            )
        }
    }

    WithHeader.displayName = `WithHeader(${WrappedComponent.name})`

    return WithHeader
}
