import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './TutorialVideo.module.css'

const poster = `/${process.env.REACT_APP_VARIATION_PATH}/video/TutorialVideo/preview.png`
const tutorial = `/${process.env.REACT_APP_VARIATION_PATH}/video/TutorialVideo/tutorial.mp4`

const TutorialVideo = ({ className }) => (
    <video poster={poster} className={cx(styles.video, className)} controls>
        <source src={tutorial} type="video/mp4" />
    </video>
)

TutorialVideo.propTypes = {
    className: PropTypes.string,
}

export default TutorialVideo
