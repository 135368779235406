import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import Box from 'components/common/Box'
import Overlay from 'components/common/Overlay'
import Text from 'components/common/Text'
import TextIconButton from 'components/common/Buttons/TextIconButton'

import styles from './WarningModal.module.css'

const back = `/${process.env.REACT_APP_VARIATION_PATH}/icons/arrow-left.png`
const camera = `/${process.env.REACT_APP_VARIATION_PATH}/icons/camera.png`

const WarningModal = ({ children, className, title, hideOverlays, ...props }) => {
    const { t } = useTranslation()
    return (
        <Overlay className={styles.wrapper} {...props}>
            <Box className={cx(styles.box, className)}>
                {title && <Text className={styles.title}>{title}</Text>}
                <div className={styles.buttons}>
                    <Link to="/">
                        <TextIconButton
                            className={styles.button}
                            text={t('warning.leave')}
                            icon={back}
                            onClick={() => hideOverlays()}
                        />
                    </Link>
                    <TextIconButton
                        className={styles.button}
                        text={t('warning.stay')}
                        icon={camera}
                        onClick={() => hideOverlays()}
                    />
                </div>
            </Box>
        </Overlay>
    )
}

WarningModal.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string,
}

export default WarningModal
